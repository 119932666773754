import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";
import settings from "../containers/Setting/settingsReducer";
export default configureStore({
  reducer: {
    root: rootReducer,
    settings: settings,
    // counter: counter,
  },
});
