export const labelsMap = {


  english: {
    HOME_HEADING: "Simplest way to protect your teeth",
    HOME_SUBHEADING: "Select the language",
    LOGIN_HEADING: "Hi There!",
    LOGIN_SUBHEADING:
      "Let’s get your journey started towards a healthier mouth.",
    LOGIN_INPUT_NAME: "Name",
    LOGIN_INPUT_MOBILE: "Mobile Number",
    LOGIN_INPUT_OTP: "OTP",
    LOGIN_BUTTON_SEND_OTP: "Send OTP",
    LOGIN_BUTTON_SUBMIT_OTP: "Submit OTP",
    LOGIN_BUTTON_RESEND_OTP: "Didn’t get OTP? Resend",
    LOGIN_BUTTON_EDIT_NUMBER: "Edit Number",
    LOGIN_MESSAGE_OTP_SENT: "OTP Sent",
    LOGIN_MESSAGE_INVALID_OTP: "Please provide valid OTP",
    LOGIN_MESSAGE_OTP_ERROR: "Something went wrong while sending OTP",
    LOGIN_MESSAGE_OTP_SUBMIT_ERROR: "Something went wrong while submitting OTP",
    LOGIN_MESSAGE_INVALID_DATA: "Please fill valid data",
    SCAN_HEADING_TEETH: "Teeth",
    SCAN_INSTRUCTIONS_MAP: {
      front: [
        "Watch the guiding video carefully",
        "Fit your front angle jaw in the frame",
        "Select the click button",
      ],
      upper: [
        "Watch the guiding video carefully",
        "Fit your upper angle jaw in the frame",
        "Select the click button",
      ],
      lower: [
        "Watch the guiding video carefully",
        "Fit your lower angle jaw in the frame",
        "Select the click button",
      ],
    },
    SCAN_VIDEO_INSTRUCTIONS_MAP: {
      data: [
        "Watch the guiding video carefully",
        "Stand as close as possible. Show your teeth as shown in the video.",
        "Click pause button once all 3 angles are recorded.",
      ],
    },
    SCAN_SUBMIT_BUTTON: "Scan teeth",
    ANGLE_LIST: ["Front", "Upper", "Lower"],
    VIDEO_MODE: "Record Video",
    REPORT_HEADING: "Your Dental Report",
    REPORT_SUB_SECTION_HEADING: "Your Estimated Treatment Options",
    NO_DETECTIONS_IN_ANGLE:
      "No diseases found. If the image is improper, please retry.",
    BOOK_APPOINTMENT_BUTTON: "Book Appointment",
    DONWLOAD_REPORT_BUTTON: "Download Report",
    EXIT_REPORT_BUTTON: "EXIT",
    APPOINTMENT_MESSAGE: "Call this number to book an appointment",
    APPOINTMENT_SECOND_SECTION_HEADING:
      "Fast Track & Evening Clinic at the School of Dental Sciences",
    APPOINTMENT_MORNING_SHIFT_TIMING: "Morning 9 am to Evening 5 pm",
    APPOINTMENT_EVENING_SHIFT_TIMING: "Evening 5 pm to 8.30 pm",
    DOWNLOAD_HEADING1:
      "Use DentalDost app to follow right oral health habits and hygiene practices",
    DOWNLOAD_HEADING2: "Scan this QR code and Download Now",
  },


  diseasesMap: {
    "pit and fissure caries": {
      color: "#0000FF",
      english: {
        name: "Pit and fissure caries",
        description: "Start of a tooth decay",
        treatment: "Tooth colour filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "थोडा कीड",
        description: "दात किडण्याची प्रारंभिक सुरुवात",
        treatment: "दाता-समान रंग असणारी फीलिन्ग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "शुरुआती कीड़",
        description: "कीड़ की शुरुआत",
        treatment: "दाँत के रंग का फिलिंग",
        price: "₹ 200 - 350",
      },
    },
    "deep caries": {
      color: "#FF8300",
      english: {
        name: "Deep caries",
        description: "A deeper cavity that might be painful",
        treatment:
          "Deep filling/ Root canal treatment according to Xray finding",
        price: "₹ 500 - 1140",
      },
      marathi: {
        name: "खोल कीड",
        description: "खोल कीड जी वेदनादायक असू शकते",
        treatment:
          "रूट कॅनॉल ट्रीटमेंट/फीलिन्ग करणे हे xray तपासणीवर अवलंबून आहे",
        price: "₹ 500 - 1140",
      },
      hindi: {
        name: "गहरा कीड़",
        description: "एक गहरा कीड़ा जो दर्दनाक हो सकता है",
        treatment: "ग़हरा फिलिंग/रूट कैनाल उपचार एक्सरे फाइंडिंग के अनुसार",
        price: "₹ 500 - 1140",
      },
    },
    stains: {
      color: "#FF0000",
      english: {
        name: "Stains",
        description: "Brownish to blackish discoloration",
        treatment: "Teeth cleaning and polishing",
        price: "₹ 150",
      },
      marathi: {
        name: "दातांवर डाग",
        description: "तपकिरी ते काळ्या रंगाचा दाग",
        treatment: "दात स्वच्छ करणे",
        price: "₹ 150",
      },
      hindi: {
        name: "दांतों पर दाग",
        description: "भूरे से काले रंग का मलिनकिरण",
        treatment: "दांतो को सफाई",
        price: "₹ 150",
      },
    },
    calculus: {
      color: "#00FF00",
      english: {
        name: "Calculus",
        description: "Hard yellowish-white deposits on teeth/gums",
        treatment: "Deep Scaling",
        price: "₹ 150",
      },
      marathi: {
        name: "टार्टर",
        description: "दात/हिरड्यांवर कडक पिवळसर-पांढरा साठा",
        treatment: "संपूर्ण स्वच्छता आणि पॉलिशिंग",
        price: "₹ 150",
      },
      hindi: {
        name: "टार्टर",
        description: "दांतों/मसूड़ों पर सख्त पीला-सफेद जमाव",
        treatment: "गहराई से सफाई",
        price: "₹ 150",
      },
    },
    "dental fluorosis": {
      color: "#FF9A8C",
      english: {
        name: "Fluorosis",
        description: "Whitish brown spots due to excess fluoride",
        treatment: "Veneer/ Cap",
        price: "₹ 920",
      },
      marathi: {
        name: "फ्लोरोसिस",
        description: "फ्लोराईड जास्त असल्यामुळे झालेले पांढरे तपकिरी डाग",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
      hindi: {
        name: "फ्लोरोसिस",
        description: "अत्यधिक फ्लोराइड के कारण सफेद भूरे रंग के धब्बे",
        treatment: "विनियर / कैप",
        price: "₹ 920",
      },
    },
    "missing tooth": {
      color: "#4B8378",
      english: {
        name: "Missing tooth",
        description: "A gap due to missing tooth",
        treatment: "Implant/ Bridge/ Denture",
        price: "₹ 10000 / 900 / 200",
      },
      marathi: {
        name: "गहाळ दात",
        description: "दात नसल्यामुळे अंतर",
        treatment: "इम्प्लांट / ब्रिज / कवळी",
        price: "₹ 10000 / 900 / 200",
      },
      hindi: {
        name: "दांत में गॅप",
        description: "दांत न होने के कारण गैप",
        treatment: "इम्प्लांट / ब्रिज / डेंचर",
        price: "₹ 10000 / 900 / 200",
      },
    },
    fracture: {
      color: "#808080",
      english: {
        name: "Fracture",
        description: "Semi or fully broken tooth",
        treatment: "Filling/ Cap",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "तुटलेला दात",
        description: "अर्धा किंवा पूर्ण तुटलेला दात",
        treatment: "फिलिंग / कॅप",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "टूटा हुआ दांत",
        description: "आधा या पूरी तरह से टूटा हुआ दांत",
        treatment: "फिलिंग / कैप",
        price: "₹ 200 / 920",
      },
    },
    "root stump": {
      color: "#FF00FF",
      english: {
        name: "Root stump",
        description: "Pieces of root/tooth remaining",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "रूट स्टंप",
        description: "कीड खोल असल्यामुळे फक्त दातांचे मूळ वाचले आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "रूट स्टंप",
        description: "जड़/दाँत के बचे हुए टुकड़े",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
    },
    "grossly decayed": {
      color: "#FB6090",
      english: {
        name: "Grossly decayed tooth",
        description: "Minimum tooth structure left due to deep cavity",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "पूर्ण किडलेला दात",
        description: "कीड खोल गेल्यामुळे दातांचा लहान भाग शिल्लक आहे",
        treatment: "दात काढणे",
        price: "₹ 40",
      },
      hindi: {
        name: "पूरा खराब दांत",
        description:
          "गहरा कीड़ा होने के कारण दांत का केवल एक छोटा सा हिस्सा ही बचा है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
    },
    malaligned: {
      color: "#FFFF00",
      english: {
        name: "Malaligned",
        description: "Crooked teeth, not in alignment",
        treatment: "Braces/ Clear Aligner",
        price: "₹ 15000",
      },
      marathi: {
        name: "वेडे-वाकडे दात",
        description: "दात संरेखित नाहीत",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "टेढे दांत",
        description: "टेढे मेढे दांत",
        treatment: "ब्रेसेस / क्लियर एलाइनर्स",
        price: "₹ 15000",
      },
    },
    attrition: {
      color: "#00FFFF",
      english: {
        name: "Attrition",
        description: "Worn off tooth on biting surface",
        treatment: "Filling/ Night Guard",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "दातांची झीज झाली आहे",
        description: "दातांची अत्याधिक झीज झाल्याने ठणक लागू शकते",
        treatment: "फिलिंग / कॅप / नाईट गार्ड",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "दंत संघर्षण",
        description: "घिसा हुआ दांत",
        treatment: "फिलिंग / नाइट गार्ड",
        price: "₹ 200 / 920",
      },
    },
    abfraction: {
      color: "#F9B208",
      english: {
        name: "Abfraction",
        description: "V-shaped defect on the tooth near gum",
        treatment: "Filling",
        price: "₹ 200",
      },
      marathi: {
        name: "अब्रेक्शन",
        description: "हिरड्याजवळच्या दातावर V आकाराचा दोष",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
      hindi: {
        name: "अब्रेक्शन",
        description: "वी के आकार का दांत पर घिस जाना",
        treatment: "फिलिंग",
        price: "₹ 200",
      },
    },
    diastema: {
      color: "#7c6e61",
      english: {
        name: "Diastema",
        description: "A gap between upper front two teeth",
        treatment: "Filling/ Veneer",
        price: "₹ 200 / 920",
      },
      marathi: {
        name: "डायस्टेमा",
        description: "वरच्या दोन दातांमधील अंतर",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
      hindi: {
        name: "सामने के दांतों के बीच फांक",
        description: "सामने के दांतों के बीच फांक",
        treatment: "विनियर / फिलिंग",
        price: "₹ 200 / 920",
      },
    },
    'tooth gap': {
      color: "#3B0918",
      english: {
        name: "Tooth gap",
        description: "A gap between two teeth",
        treatment: "Orthodontic treatment",
        price: "₹ 15000",
      },
      marathi: {
        name: "दोन दातांमधील फट",
        description: "दातांमधील अंतर",
        treatment: "ब्रेसेस / क्लिअर अलाइनर",
        price: "₹ 15000",
      },
      hindi: {
        name: "दो दांतों के बीच फांक",
        description: "दो दांतों के बीच फांक",
        treatment: "दांतो को सीधा करने की इलाज",
        price: "₹ 15000",
      },
    },
    "silver filling": {
      color: "#73777B",
      english: {
        name: "Silver filling",
        description: "Black color filling which can be damaging in long run",
        treatment: "Replace with safe tooth colored filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "चांदी भरलेला दात",
        description: "चांदी मध्ये असलेला पारा हानिकारक आहे",
        treatment: "टूथ कलर फिलिंग्सने बदलून घ्या",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "सिल्वर फिलिंग",
        description: "चांदी/काले रंग का दाँत फिलिंग",
        treatment: "सुरक्षित दांत के रंग के फिलिंग से बदलें",
        price: "₹ 200 - 350",
      },
    },
    "composite filling": {
      color: "#C85C5C",
      english: {
        name: "Composite filling",
        description: "Tooth colored filling",
        treatment: "Replace if broken",
        price: "₹ 350",
      },
      marathi: {
        name: "कम्पोजिट फीलिंग",
        description: "दातासमान रंग असणारी फिलिंग",
        treatment: "तुटल्यास बदला",
        price: "₹ 350",
      },
      hindi: {
        name: "कम्पोजिट फीलिंग",
        description: "दांतों के रंग का भराव",
        treatment: "अगर टूटा हुआ है तो बदलें",
        price: "₹ 350",
      },
    },
    "metal crown": {
      color: "#FF0075",
      english: {
        name: "Metal crown",
        description: "Black metal cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "ब्लॅक मेटल कॅप",
        description: "काळ्या धातूचा कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल कैप",
        description: "काली धातु के कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    "ceramic crown": {
      color: "#77D970",
      english: {
        name: "Ceramic crown",
        description: "Tooth coloured cap",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "सिरेमिक कॅप",
        description: "दातासमान रंग असणारी कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "सिरेमिक कैप",
        description: "दांत के रंग का कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    abrasion: {
      color: "#774360",
      english: {
        name: "Abrasion",
        description: "Flattening of front surface of the tooth",
        treatment: "Tooth colour filling",
        price: "₹ 350",
      },
      marathi: {
        name: "दातांचे घर्षण",
        description: "दातांच्या समोरील भागाचे घर्षण",
        treatment: "दातासमान फिलिंग",
        price: "₹ 350",
      },
      hindi: {
        name: "दांतों का घर्षण",
        description: "दांतों के सामने की सतह का चपटा होना",
        treatment: "दांतो के रंग के फिलिंग",
        price: "₹ 350",
      },
    },
    denture: {
      color: "#A64B2A",
      english: {
        name: "Denture",
        description: "Artificial set of teeth",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "कवळी",
        description: "खोट्या दातांनी बनवलेले कवळी",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "नकली दांत",
        description: "कृत्रिम दांतों की पंक्ति",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    appliances: {
      color: "#B25068",
      english: {
        name: "Appliances",
        description: "Post ortho treatment retainer",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट झाल्यानंतर असलेले उपकरण",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "उपकरण",
        description: "ऑर्थो ट्रीटमेंट के बाद रिटेनर",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    "metal ceramic": {
      color: "#DACC96",
      english: {
        name: "Metal ceramic crown",
        description: "Tooth colored cap with metal lining",
        treatment: "Keep it under observation",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "मेटल सिरेमिक कॅप",
        description: "धातूच्या अस्तरांसह दात रंगीत कॅप",
        treatment: "निरीक्षणाखाली ठेवा",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "मेटल सिरेमिक कैप",
        description: "चाँदी लाइनिंग के साथ दाँत कलर कैप",
        treatment: "निगरानी में रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    "impacted 3rd molar / pericoronitis": {
      color: "#6D8B74",
      english: {
        name: "Impacted third molar",
        description: "Partially visible or not visible wisdom tooth",
        treatment: "Extraction",
        price: "₹ 100 - 500",
      },
      marathi: {
        name: "अक्कल दाढ",
        description: "अक्कल दाढ, अर्धी किंवा पुर्णपणे जबड्या मध्ये रूतलेली आहे",
        treatment: "अक्कल दाढ काढावी लागेल",
        price: "₹ 100 - 500",
      },
      hindi: {
        name: "अक्ल दाढ़",
        description:
          "आंशिक रूप से दिखाई देने वाला या न दिखाई देने वाला अकल दाढ़",
        treatment: "दांत निकालना",
        price: "₹ 100 - 500",
      },
    },
    "discolored tooth": {
      color: "#F9CEEE",
      english: {
        name: "Discoloured tooth",
        description: "Changed tooth color than normal",
        treatment: "Root canal treatment",
        price: "₹ 990+",
      },
      marathi: {
        name: "रंग बदललेला दात",
        description: "दातांना मार लागल्यामुळे रंग बदल झालेला दात",
        treatment: "रूट कॅनल उपचार",
        price: "₹ 990+",
      },
      hindi: {
        name: "फीका पड़ा हुआ दाँत",
        description: "बदले हुए दांतो के रंग",
        treatment: "रुट कैनाल उपचार",
        price: "₹ 990+",
      },
    },
    "food lodgement": {
      color: "#ef6e29",
      english: {
        name: "Food lodgement",
        description:
          "Food is stuck in and between your teeth, can indicate tooth decay",
        treatment: "Filling",
        price: "₹ 200 - 350",
      },
      marathi: {
        name: "दांतांमध्ये अन्न अडकलेले आहे",
        description: "दातामद्धे अन्न अडकत असल्यामुळे कीड असण्याची शक्यता आहे",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
      hindi: {
        name: "दांतों में फंसा खाना",
        description: "खाना आपके दांतों में और उनके बीच फंसा हुआ है",
        treatment: "फिलिंग",
        price: "₹ 200 - 350",
      },
    },
    braces: {
      color: "#F5A7A7",
      english: {
        name: "Braces",
        description: "Ongoing ortho treatment",
        treatment: "Maintain hygiene",
        price: "FREE scan on DentalDost",
      },
      marathi: {
        name: "ऑर्थो ब्रेसेस",
        description: "ऑर्थो उपचार सुरू आहे",
        treatment: "स्वच्छता राखणे",
        price: "डेंटलडॉस्टवर विनामूल्य स्कॅन",
      },
      hindi: {
        name: "दांतो में तार",
        description: "ऑर्थो का इलाज चल रहा है",
        treatment: "स्वच्छता बनाए रखें",
        price: "डेंटलडॉस्ट पर मुफ्त स्कैन",
      },
    },
    "receded gums": {
      color: "#AC7D88",
      english: {
        name: "Receded gums",
        description: "A part of root is visible due to gums changing position",
        treatment: "Gum Surgery",
        price: "₹ 300",
      },
      marathi: {
        name: "मूळ उघडे पडले आहे",
        description:
          "हिरड्यांची उंची कमी होते, लाल, सुजणे ही सुरुवातीची लक्षणे दिसतात",
        treatment: "हिरड्यांची शस्त्रक्रिया",
        price: "₹ 300",
      },
      hindi: {
        name: "दांतों की जड़ें उजागर हैं",
        description: "जड़ का एक भाग दिखाई देता है",
        treatment: "मसूड़े की सर्जरी",
        price: "₹ 300",
      },
    },
    mesiodens: {
      color: "#82C4C3",
      english: {
        name: "Mesiodens",
        description: "Extra tooth present between upper two front teeth",
        treatment: "Extraction",
        price: "₹ 40",
      },
      marathi: {
        name: "अतिरिक्त दात",
        description: "हा एक अतिरिक्त दात आहे",
        treatment: "काढावी लागेल",
        price: "₹ 40",
      },
      hindi: {
        name: "अतिरिक्त दांत",
        description: "यह दांत अतिरिक्त है",
        treatment: "दांत निकालना",
        price: "₹ 40",
      },
    },
    "incomplete rct": {
      color: "#BF9742",
      english: {
        name: "Incomplete RCT",
        description:
          "Root canal treatment is not complete and very harmful for the tooth",
        treatment: "Filling & Cap",
        price: "₹ 990 - 1140",
      },
      marathi: {
        name: "अपूर्ण रूट कॅनॉल ट्रीटमेंट",
        description:
          "अपूर्ण रूट कॅनॉल झाले असल्यास दातांमध्ये पुनः संक्रमण होऊ शकतो",
        treatment: "पूर्ण रूट कॅनाल उपचार",
        price: "₹ 990 - 1140",
      },
      hindi: {
        name: "अपूर्ण रूट कैनाल उपचार",
        description: "अपूर्ण रूट कैनाल उपचार जो दांतों के लिए हानिकारक होता है",
        treatment: "फिलिंग और कैप",
        price: "₹ 990 - 1140",
      },
    },
    "dislodged prosthesis / filling": {
      color: "#401f37",
      english: {
        name: "Dislodged prosthesis",
        description: "The filling/ prosthesis is either damaged or displaced",
        treatment: "Re-filling",
        price: "₹ 350",
      },
      marathi: {
        name: "निघालेले फीलिन्ग/ कॅप",
        description: "फीलिंग/ कॅप/ ब्रिज खराब किंवा विस्थापित झाले आहेत",
        treatment: "रिफिलिंग",
        price: "₹ 350",
      },
      hindi: {
        name: "ढीली कैप/ फिलिंग",
        description: "विस्थापित कैप,ब्रिज या फिलिंग",
        treatment: "दुबारा भरिये",
        price: "₹ 350",
      },
    },
    "inflammed / red gums": {
      color: "#661b40",
      english: {
        name: "Inflamed gum",
        description: "Red or swollen gums due to plaque & calculus",
        treatment: "Scaling",
        price: "₹ 150",
      },
      marathi: {
        name: "हिरड्यांना सूज",
        description: "सुजलेली आणि लाल झालेली हिरडी",
        treatment: "दात स्वच्छ करणे",
        price: "₹ 150",
      },
      hindi: {
        name: "सूजे मसूड़े",
        description: "लाल या सूजे हुए मसूड़े",
        treatment: "दांतो की सफाई",
        price: "₹ 150",
      },
    },
    "peg lateral": {
      color: "#DEB6AB",
      english: {
        name: "Peg lateral",
        description: "Smaller than normal upper front tooth",
        treatment: "Cap/ Veneer",
        price: "₹ 920",
      },
      marathi: {
        name: "छोटे दात",
        description: "समोरील छोटे दात",
        treatment: "विनियर / कॅप",
        price: "₹ 920",
      },
      hindi: {
        name: "छोटा दाँत",
        description: "सामने के छोटे दाँत",
        treatment: "विनियर/ कैप",
        price: "₹ 920",
      },
    },
  },
};
