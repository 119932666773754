import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  CircularProgress,
  Stepper,
  Step,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@material-ui/icons";
import TabContext from "@material-ui/lab/TabContext";
import TabPanel from "@material-ui/lab/TabPanel";
import TabList from "@material-ui/lab/TabList";
import { useSelector, useDispatch } from "react-redux";
import {
  getTestId,
  getPatientId,
  getToken,
  getLanguage,
  getName,
  getMobile,
  resetState,
} from "../../store/rootReducer";
import { getTestReport } from "../../services/rootService";
import { labelsMap } from "../../utils/i18n";
import { useHistory } from "react-router";
import "./report.css";
import moment from "moment";

import { Typography } from "@material-ui/core";
import { Breadcrumbs, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

export default function Report() {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: "#fff",
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: "2rem",
      color: "#333",
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      height: "90%",
    },
    dataGrid: {
      width: "100%",
    },
    flexContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "90%",
    },
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const IMAGE_URL = "https://ai.dentaldost.co";
  // const testId = "6375d0253e5e8e276e7b41d1"; //useSelector(getTestId);
  const testId = useSelector(getTestId);
  const name = useSelector(getName);
  const mobile = useSelector(getMobile);
  const patientId = useSelector(getPatientId);
  // const authToken =
  // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRvbmFua3VyMDA3QGdtYWlsLmNvbSIsImlkIjoiNjE3MDE3ZjVhYTNjNDM1YTQyYmYzMDhkIiwiaWF0IjoxNjY4NjY1NjU2fQ.b8NYAc3-igBmmNGI8zY9vnfNtCXCOq1sKriw5n8hMTA"; //useSelector(getToken);
  const authToken = useSelector(getToken);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [imageUrl, setImageUrl] = useState("");
  const [reportData, setReportData] = useState();
  const [diseasesList, setDiseasesList] = useState([]);
  const language = useSelector(getLanguage);
  const [selectedDiseaseTypes, setSelectedDiseaseTypes] = useState([]);
  const {
    REPORT_HEADING,
    REPORT_SUB_SECTION_HEADING,
    ANGLE_LIST,
    BOOK_APPOINTMENT_BUTTON,
    DONWLOAD_REPORT_BUTTON,
    NO_DETECTIONS_IN_ANGLE,
  } = labelsMap[language];

  const getTreatmentsColor = (disease) => {
    return labelsMap.diseasesMap[disease.toLowerCase()].color;
  };
  const canvasRef = useRef();

  const getTreatments = (disease) => {
    // console.log({ disease: disease.toLowerCase() });
    // if (
    //   labelsMap.diseasesMap[disease.toLowerCase()] &&
    //   labelsMap.diseasesMap[disease.toLowerCase()][language]
    // ) {
    return labelsMap.diseasesMap[disease.toLowerCase()][language];
    // }
  };

  const drawRectangle = (ctx, coordinates, color, offsetX, offsetY) => {
    const [x1, y1, x2, y2] = coordinates;
    ctx.globalAlpha = 0.7;

    ctx.fillStyle = color;
    ctx.fillRect(offsetX + x1, offsetY + y1, x2 - x1, y2 - y1);
  };

  const drawAnnotations = (context, offsetX, offsetY) => {
    const diseaseMap = fillInDiseaseInformation(diseasesList);
    selectedDiseaseTypes.forEach((diseaseType) => {
      const diseases = diseaseMap[diseaseType];
      const color = getTreatmentsColor(diseaseType);
      if (diseases) {
        diseases.forEach((disease) => {
          const coordinates = disease.coordinates;
          coordinates.forEach((coord) =>
            drawRectangle(context, coord, color, offsetX, offsetY)
          );
        });
      }
    });
  };

  useEffect(() => {
    if (!reportData) {
      return;
    }
    let angle;
    switch (activeStep) {
      case 0: // Upper
        angle = "front";
        break;
      case 1: // Lower
        angle = "upper";
        break;
      case 2: // Front
        angle = "lower";
        break;
      default:
        setDiseasesList(reportData.disease);
        return;
    }
    let detection = reportData.test.find(
      (d) => d.angle.toLowerCase() === angle
    );
    if (detection) {
      const uniqueDiseaseTypes = [
        ...new Set(
          detection.diseases.map((disease) => disease.name.toLowerCase())
        ),
      ];
      setSelectedDiseaseTypes(uniqueDiseaseTypes);
      setDiseasesList(detection.diseases);
      const anglesList = ["front", "upper", "lower"];
      const path = reportData.test.find(
        (data) => data.angle.toLowerCase() === anglesList[activeStep]
      );
      if (path && path.base_file_url) {
        setImageUrl(IMAGE_URL + path.base_file_url);
      }
      // setSelectedDiseaseTypes([]);
    } else {
      console.error(`Detection with angle "${angle}" not found`);
    }
  }, [activeStep, reportData]);

  useEffect(() => {
    if (!imageUrl) {
      return;
    }
    const image = new Image();
    image.src = imageUrl;
    image.onload = () => {
      const canvas = canvasRef.current;
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
      const context = canvas.getContext("2d");
      const offsetX = (canvas.width - image.width) / 2;
      const offsetY = (canvas.height - image.height) / 2;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, offsetX, offsetY, image.width, image.height);
      drawAnnotations(context, offsetX, offsetY);
    };
  }, [imageUrl, selectedDiseaseTypes, diseasesList]);

  const history = useHistory();
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const fillInDiseaseInformation = (diseases) => {
    const diseaseMap = {};

    diseases.forEach((disease) => {
      const diseaseType = disease.name.toLowerCase();

      if (Object.keys(labelsMap.diseasesMap).includes(diseaseType)) {
        if (!diseaseMap[diseaseType]) {
          diseaseMap[diseaseType] = [];
        }
        diseaseMap[diseaseType].push(disease);
      }
    });

    return diseaseMap;
  };

  const waitTillTestCompletes = async () => {
    try {
      const result = await getTestReport(authToken, testId, patientId);
      if (result && result.data) {
        setReportData(result.data);
      }
      setLoadingReportData(false);
      return true;
    } catch (error) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      waitTillTestCompletes();
    }
  };

  const getImageByAngleIndex = () => {
    const anglesList = ["front", "upper", "lower"];
    if (reportData && reportData.test) {
      const path = reportData.test.find(
        (data) => data.angle.toLowerCase() === anglesList[activeStep]
      );
      if (path && path.base_file_url) {
        setImageUrl(IMAGE_URL + path.base_file_url);
      }
    }
  };

  useEffect(() => {
    getImageByAngleIndex();
  }, [reportData, activeStep]);

  useEffect(() => {
    waitTillTestCompletes();
  }, []);

  let diseasesKeys = Object.keys(fillInDiseaseInformation(diseasesList));

  const handleDiseaseTypeChange = (event) => {
    const diseaseName = event.target.name;
    if (selectedDiseaseTypes.includes(diseaseName)) {
      setSelectedDiseaseTypes(
        selectedDiseaseTypes.filter((item) => item !== diseaseName)
      );
    } else {
      setSelectedDiseaseTypes([...selectedDiseaseTypes, diseaseName]);
    }
  };

  const handleDiseaseClick = (disease) => {
    if (selectedDiseaseTypes.includes(disease)) {
      setSelectedDiseaseTypes(
        selectedDiseaseTypes.filter((item) => item !== disease)
      );
    } else {
      setSelectedDiseaseTypes([...selectedDiseaseTypes, disease]);
    }
  };

  const handleLogout = () => {
    dispatch(resetState());
    history.push("/");
  };

  return (
    <div
      style={{
        height: "100vh",
      }}
    >
      {loadingReportData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100%",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={0}
            style={{
              height: "100%",
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              x1={6}
              style={{
                width: "100%",
                display: "flex",
              }}
              className="responsive-height"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Breadcrumbs aria-label="breadcrumb" className={classes.header}>
                  <Link color="inherit" component={RouterLink} to="/patients">
                    Patients
                  </Link>
                  <Link color="inherit" component={RouterLink} to="/Tests">
                    Tests
                  </Link>
                  <Typography color="textPrimary">Report</Typography>
                </Breadcrumbs>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "1em",
                    width: "80%",
                    height: "65%",
                  }}
                >
                  <div style={{ height: "2em" }}></div>
                  <div
                    className="heading1"
                    style={{ textAlign: "left", fontSize: "1.2em" }}
                  >
                    {REPORT_HEADING}
                  </div>
                  <div style={{ height: "0.5em" }}></div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="logo"
                        style={{ width: "10%", objectFit: "contain" }}
                        src={process.env.PUBLIC_URL + "/images/user_icon.png"}
                      />
                      &nbsp; <span style={{ fontSize: "1.3em" }}>{name}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="logo"
                        style={{ width: "10%", objectFit: "contain" }}
                        src={process.env.PUBLIC_URL + "/images/mobile_icon.png"}
                      />
                      &nbsp; <span style={{ fontSize: "1.3em" }}>{mobile}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="time logo"
                        style={{ width: "10%", objectFit: "contain" }}
                        src={process.env.PUBLIC_URL + "/images/icons8-time.svg"}
                      />
                      &nbsp;{" "}
                      <span style={{ fontSize: "1.3em" }}>
                        {moment(reportData.testDate).format(
                          "MMMM DD, YYYY hh:mm A"
                        )}
                      </span>
                    </div>
                  </div>
                  <div style={{ height: "0.5em" }}></div>
                  <Stepper nonLinear activeStep={activeStep}>
                    {ANGLE_LIST.map((label, index) => (
                      <Step key={label}>
                        <Button
                          className="buttonClass"
                          color="inherit"
                          onClick={handleStep(index)}
                          style={{
                            backgroundColor:
                              activeStep === index
                                ? "rgb(5, 38, 121)"
                                : "white",
                            color:
                              activeStep === index
                                ? "white"
                                : "rgb(5, 38, 121)",
                            border: "1px solid rgb(5, 38, 121)",
                            fontWeight: "bolder",
                          }}
                        >
                          {label}
                        </Button>
                      </Step>
                    ))}
                  </Stepper>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      textAlign: "center",
                      width: "100%",
                      height: "70%",
                    }}
                  >
                    <div
                      style={{
                        width: "4em",
                        height: "4em",
                        background: "white",
                        // -moz-border-radius: "50px",
                        // -webkit-border-radius: "50px",
                        borderRadius: "50px",
                        position: "absolute",
                        top: "45%",
                        left: "-10%",
                        borderColor: "#052679",
                        borderWidth: "0.1em",
                        borderStyle: "solid",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          let nextStep = activeStep - 1;
                          if (nextStep < 0) {
                            nextStep = 2;
                          }
                          setActiveStep(nextStep);
                        }}
                        style={{
                          padding: "0.1em",
                          color: "#052679",
                        }}
                      >
                        <ArrowBackOutlined style={{ fontSize: 50 }} />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "rgb(0 36 70)",
                      }}
                    >
                      <canvas
                        ref={canvasRef}
                        className="image image-contain"
                        style={{ objectFit: "contain" }}
                      ></canvas>
                    </div>
                    <div
                      style={{
                        width: "4em",
                        height: "4em",
                        background: "white",
                        // -moz-border-radius: "50px",
                        // -webkit-border-radius: "50px",
                        borderRadius: "50px",
                        position: "absolute",
                        top: "45%",
                        right: "-10%",
                        borderColor: "#052679",
                        borderWidth: "0.1em",
                        borderStyle: "solid",
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          let nextStep = activeStep + 1;
                          if (nextStep > 2) {
                            nextStep = 0;
                          }
                          setActiveStep(nextStep);
                        }}
                        style={{
                          padding: "0.1em",
                          color: "#052679",
                        }}
                      >
                        <ArrowForwardOutlined style={{ fontSize: 50 }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div style={{ height: "4em" }}></div>
                <div style={{ width: "100%", marginTop: "2em" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* <div style={{ width: "40%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        handleClickBookAppointment();
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>
                        {" "}
                        {BOOK_APPOINTMENT_BUTTON}
                      </span>
                    </Button>
                  </div> */}
                    {/* <div style={{ width: "40%" }}>
                    <Button
                      style={{ backgroundColor: "#052679", color: "white" }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        history.push("/download");
                      }}
                    >
                      <span style={{ fontSize: "1.5em" }}>
                        {" "}
                        {DONWLOAD_REPORT_BUTTON}
                      </span>
                    </Button>
                  </div> */}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              x1={6}
              style={{}}
              className="responsive-height"
            >
              <div
                style={{
                  padding: "4vh",
                  height: "100%",
                  overflow: "scroll",
                }}
              >
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "rgb(5, 38, 121)",
                      color: "white",
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "start",
                    backgroundColor: "#D9E9FC",
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      padding: "1em",
                    }}
                  >
                    <img
                      alt="logo"
                      style={{ width: "25%", objectFit: "contain" }}
                      src={process.env.PUBLIC_URL + "/images/logo.png"}
                    />
                  </div>
                  <div>
                    <div className="heading-scan" style={{ textAlign: "left" }}>
                      {REPORT_SUB_SECTION_HEADING}
                    </div>
                  </div>

                  <div style={{ height: "1em" }}></div>
                  {diseasesKeys.length > 0 ? (
                    diseasesKeys.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          textAlign: "left",
                          paddingTop: "1em",
                          paddingLeft: "2em",
                          paddingRight: "2em",
                          width: "100%",
                        }}
                        onClick={() => handleDiseaseClick(item)}
                      >
                        <div
                          className="treatment-text"
                          style={{
                            textAlign: "left",
                            pointerEvents: "none",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                              backgroundColor: "#D9E9FC",
                            }}
                          >
                            {/* Checkbox for disease selection */}
                            <Checkbox
                              checked={selectedDiseaseTypes.includes(item)}
                              onChange={handleDiseaseTypeChange}
                              name={item}
                              color="primary"
                            />
                            <div
                              style={{
                                border: "1px solid black",
                                height: "3em",
                                width: "2em",
                                backgroundColor: getTreatmentsColor(item),
                              }}
                            ></div>
                            &nbsp; &nbsp;
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <span>
                                <strong>
                                  {getTreatments(item) &&
                                  getTreatments(item).name
                                    ? getTreatments(item).name
                                    : ""}
                                </strong>
                              </span>
                              <span style={{ fontSize: "0.8em" }}>
                                {getTreatments(item) &&
                                getTreatments(item).description
                                  ? getTreatments(item).description
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div style={{ height: "1em" }}></div>
                          <div
                            style={{
                              paddingLeft: "1em",
                              fontSize: "0.8em",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                backgroundColor: "#D9E9FC",
                              }}
                            >
                              <span>
                                {getTreatments(item) &&
                                getTreatments(item).treatment
                                  ? getTreatments(item).treatment
                                  : ""}
                              </span>
                              {/* <span>
                              {getTreatments(item) && getTreatments(item).price
                                ? getTreatments(item).price
                                : ""}
                            </span> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div style={{ textAlign: "center", paddingTop: "2em" }}>
                      <p className="no-detections">{NO_DETECTIONS_IN_ANGLE}</p>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
