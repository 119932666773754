import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
} from "@material-ui/core";


import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


import { useDispatch, useSelector } from "react-redux";
import { getLanguage, getPatientId, getToken, setMobile, setName, setTestId, resetState } from "../../store/rootReducer";
import {
  bookAppointment,
  getPatients,
  getTests,
} from "../../services/rootService";
import { useHistory } from "react-router";
import { labelsMap } from "utils/i18n";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
export default function Tests() {
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: '#fff',
      height: '100%',
      width: '100%',
      padding: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: '2rem',
      color: '#333',
      fontWeight: 600,
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      height: '90%',
    },
    dataGrid: {
      width: '100%',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90%',
    },
    logoutButton: {
      position: 'fixed',
      right: theme.spacing(2),
      top: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const handleLogout = () => {
    dispatch(resetState())
    history.push("/");

  }
  const language = useSelector(getLanguage);
  const {
    DOWNLOAD_HEADING1,
    DOWNLOAD_HEADING2,
    EXIT_REPORT_BUTTON,
    BOOK_APPOINTMENT_BUTTON,
  } = labelsMap[language];
  const history = useHistory();
  const authToken = useSelector(getToken);

  const [userData, setUserData] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const [responseData, setResponseData] = useState([]);
  let patientId = useSelector(getPatientId)

  // const handleClickBookAppointment = async () => {
  //   try {
  //     // const result =
  //     await bookAppointment(authToken);
  //     history.push("/appointment");
  //   } catch (error) {
  //     console.log(error);
  //     history.push("/appointment");
  //   }
  // };

  const testColumns = [
    {
      field: "id",
      headerName: "Test No",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      align: "center",
      width: 250,
      renderCell: (data) => {
        return moment(data.row.date).format("DD/MM/YYYY hh:mm A");
      },
    },
    {
      field: "score",
      headerName: "Score",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "testMode",
      headerName: "Test Mode",
      headerAlign: "center",
      align: "center",
      width: 180,
      renderCell: (data) => {
        return data.row.isKiosk ? "Kiosk" : "App";
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {/* <Button
              variant="gradient"
              onClick={() => {
                dispatch(setTestId(params.row.tid));
                history.push("/report");
              }}
              color="info"
            >
              Details
            </Button> */}
            <Button
              style={{ backgroundColor: "#052679", color: "white" }}
              variant="contained"
              fullWidth
              onClick={() => {
                dispatch(setTestId(params.row.tid));
                console.log(params)
                history.push("/report");
              }}
            >
              <span style={{ fontSize: "1em" }}>View Report</span>
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getTests(patientId, authToken).then((result) => {
      console.log(result.data);
      if (result && result.data && result.data.tests) {
        setUserData(result.data.tests.reverse());
      }
    });
  }, []);
  


  return (
    <>
      <Box className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.header}>
          <Link color="inherit" component={RouterLink} to="/patients">
            Patients
          </Link>
          <Typography color="textPrimary">Tests</Typography>
        </Breadcrumbs>


        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} md={12} lg={12} x1={12}>
            <Box className={classes.flexContainer}>
              <Typography variant="h1" className={classes.title}>
                Tests
              </Typography>
              <DataGrid
                className={classes.dataGrid}
                rows={userData}
                columns={testColumns}
                disableSelectionOnClick
                pageSize={20}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Button
        className={classes.logoutButton}
        variant="contained"
        style={{ backgroundColor: "rgb(5, 38, 121)", color: "white" }}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </>
  );
}
