import { createSlice } from "@reduxjs/toolkit";

const anglesList = ["front", "upper", "lower"];

// Initialize state from localStorage
const localStorageLocation = localStorage.getItem("location") || "";
const previousState = JSON.parse(localStorage.getItem("rootState")) || {};

export const root = createSlice({
  name: "root",
  initialState: {
    agentId:"",
    token: "",
    testId: "",
    patientId: "",
    mobile: "",
    name: "",
    language: "english",
    ...previousState,
    location: localStorageLocation,

  },
  reducers: {

    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setName: (state, { payload }) => {
      state.name = payload;
    },
    setMobile: (state, { payload }) => {
      state.mobile = payload;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setTestId: (state, { payload }) => {
      state.testId = payload;
    },

    setAgentId: (state, { payload }) => {
      state.agentId = payload;
    },
    setPatientId: (state, { payload }) => {
      state.patientId = payload;
    },

    resetState: (state, { }) => {
      state.token = "";
      state.testId = "";
      state.agentId = "";
      state.patientId= "";
      state.mobile= "";
      state.name= "";
      state.language= "english";
      localStorage.setItem("rootState", JSON.stringify({}));


    },
    saveState: (state) => {
      localStorage.setItem("rootState", JSON.stringify(state));
    },
  },
});

export const {
  setToken,
  setName,
  setMobile,
  setTestId,
  setAgentId,
  resetState,
  saveState,
  setPatientId
} = root.actions;
export const getToken = (state) => state.root.token;
export const getAgentId = (state) => state.root.agentId;
export const getTestId = (state) => state.root.testId;
export const getName = (state) => state.root.name;
export const getMobile = (state) => state.root.mobile;
export const getPatientId = (state) => state.root.patientId;
export const getLanguage = (state) => state.root.language;

export const getLocation = (state) => state.root.location; // New location selector

export default root.reducer;
