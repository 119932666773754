import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
// import "./index.css";

// import Setting from "./containers/Setting/Setting";

// import MainLayout from "./layouts/MainLayout";
import EmptyLayout from "./layouts/EmptyLayout";

import { getTheme } from "./containers/Setting/settingsReducer";
import Login from "./containers/Login/Login";
import Report from "./containers/Report/Report";
// import { useIdleTimer } from "react-idle-timer";
import { resetState, saveState } from "store/rootReducer";
import { makeStyles } from "@material-ui/core/styles";
import Patients from "containers/Patients/Patients";
import Tests from "containers/Tests/Tests";
const SESSION_IDEL_MINUTES = 1;
console.log("Init");
// const SESSION_IDEL_MINUTES = 1;

const NotFound = () => {
  return <div>NotFound</div>;
};

const DashboardRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const rootState = useSelector((state) => state.root);

  useEffect(() => {
    dispatch(saveState());
  }, [dispatch, rootState]);

  const history = useHistory();
  const handleOnIdle = (event) => {
    dispatch(resetState());

    history.push("/");
  };

  // const { getLastActiveTime } = useIdleTimer({
  //   timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
  //   onIdle: handleOnIdle,
  //   debounce: 500,
  // });
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const useStyles = makeStyles({
  app: {
    height: "100vh",
  },
});

export default function App() {
  const classes = useStyles();
  const theTheme = useSelector(getTheme);
  return (
    <MuiThemeProvider theme={createMuiTheme(theTheme)}>
      <CssBaseline />
      <div className={classes.app}>
        <Router>
          <Switch>
            <DashboardRoute exact path="/report" component={Report} />
            <DashboardRoute exact path="/patients" component={Patients} />
            <DashboardRoute exact path="/tests/" component={Tests} />
            {/* <DashboardRoute exact path="/capture" component={Capture} /> */}
            {/* <DashboardRoute exact path="/scan" component={Scan} /> */}
            {/* <DashboardRoute exact path="/login" component={Login} /> */}
            <DashboardRoute exact path="/" component={Login} />
            <EmptyRoute component={NotFound} />
          </Switch>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}
