import Axios from "axios";

// const API_URL = "https://api.dentaldost.co";
const API_URL = "https://dashboardapi.dentaldost.co";
// const API_URL = "http://192.168.0.196:3000";

const getOtp = async (mobile) => {
  return Axios.post(`${API_URL}/auth/sendOTP`, {
    mobile,
    countryCode: "+91",
  });
};

const login = async (username, password) => {
  return Axios.post(`${API_URL}/auth/agent/loginUser`, {
    email: username,
    password,
  });
};

const getPatients = async (pageNumber, pageSize, agentId, authToken) => {
  let token = authToken;
  if (!authToken) {
    try {
      token = localStorage.getItem("token") || "";
    } catch (error) { }
  }
  return Axios.get(
    `${API_URL}/agent/kiosk/getKioskUser?agentId=${agentId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const getTests = async (userId, authToken) => {
  let token = authToken;
  if (!authToken) {
    try {
      token = localStorage.getItem("token") || "";
    } catch (error) { }
  }
  return Axios.post(
    `${API_URL}/agent/patient/getUserTests`,
    {
      userId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
};

const submitOtp = async (mobile, otp, sessionId) => {
  return Axios.post(`${API_URL}/auth/verifyOTP`, {
    mobile,
    countryCode: "+91",
    otp,
    token: sessionId,
  });
};

const testInit = async (authToken) => {
  return Axios.post(
    `${API_URL}/user/test/initTest`,
    {},
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const checkVideoStatus = async (authToken, testId) => {
  return Axios.post(
    `${API_URL}/user/test/checkVideoStatus`,
    { test_id: testId, imageCount: 3, isKiosk: true },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const checkStatus = async (authToken, testId) => {
  return Axios.post(
    `${API_URL}/user/test/checkStatus`,
    { test_id: testId, imageCount: 3, isKiosk: true },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const getTestReport = async (authToken, testId, patientId) => {
  let token = authToken;
  if (!authToken) {
    try {
      token = localStorage.getItem("token") || "";
    } catch (error) { }
  }
  return Axios.post(
    `${API_URL}/user/test/getDiseaseDataByPatientId`,
    {
      test_id: testId,
      patientId: patientId
    },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    },

  );
};

const updateUser = async (authToken, name, location) => {
  return Axios.post(
    `${API_URL}/user/updateProfile`,
    { name, kioskLocation: location },
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

const signUp = async (name, mobile, location) => {
  return Axios.post(
    `${API_URL}/auth/signup`,
    { name, mobile, countryCode: "+91", isKiosk: true, kioskLocation: location }
    // {
    //   headers: { Authorization: `Bearer ${authToken}` },
    // }
  );
};

const testSubmitedKiosk = async (authToken) => {
  return Axios.get(`${API_URL}/user/testSubmitKiosk`, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
};


export {
  getTestReport,
  login,
  getPatients,
  getTests,
  API_URL,
};
