import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage, getToken, setPatientId, setName, setMobile } from ".. /../store/rootReducer";
import { getPatients } from "../../services/rootService";
import { useHistory } from "react-router";
import { labelsMap } from "utils/i18n";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { resetState } from "store/rootReducer";
import { getAgentId } from "store/rootReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: '2rem',
    color: '#333',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    height: '90%',
  },
  dataGrid: {
    width: '100%',
  },
  logoutButton: {
    position: 'fixed',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
}));


export default function Patients() {

  const classes = useStyles();

  const language = useSelector(getLanguage);
  const {
    DOWNLOAD_HEADING1,
    DOWNLOAD_HEADING2,
    EXIT_REPORT_BUTTON,
    BOOK_APPOINTMENT_BUTTON,
  } = labelsMap[language];
  const history = useHistory();
  const authToken = useSelector(getToken);
  const dispatch = useDispatch();

  const [userData, setUserData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const patientColumns = [
    {
      field: "id",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      align: "center",
      headerAlign: "center",
      width: 250,
    },
    {
      field: "Action",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      width: 250,
      renderCell: (data) => {
        return (
          <>
            {/* <MDButton variant="gradient" color="info" onClick={() => {history.push(`/tests/${data.row._id}`)}}>
              Details
            </MDButton> */}
            <Button
              style={{ backgroundColor: "#052679", color: "white" }}
              variant="contained"
              fullWidth
              onClick={() => {
                dispatch(setPatientId(data.row._id))
                dispatch(setName(data.row.name));
                dispatch(setMobile(data.row.mobile));
                history.push(`/tests/`);
              }}
            >
              <span style={{ fontSize: "1em" }}>View Tests</span>
            </Button>
            &nbsp;&nbsp;
          </>
        );
      },
    },
  ];

  const handleLogout = () => {
    dispatch(resetState())
    history.push("/");
  }
  const handlePageChange = (newPage) => {
    getKioskUsers(newPage);
  };

  // const handleClickBookAppointment = async () => {
  //   try {
  //     // const result =
  //     await bookAppointment(authToken);
  //     history.push("/appointment");
  //   } catch (error) {
  //     console.log(error);
  //     history.push("/appointment");
  //   }
  // };
  const agentId = useSelector(getAgentId)

  useEffect(() => {
    console.log("getPatients", agentId)
    getKioskUsers();
  }, []);

  const getKioskUsers = (page) => {
    try {
      const pageNumber = page === undefined ? "0" : page;
      const pageSize = "10";
      console.log(agentId)
      if (agentId && agentId.length) {
        getPatients(
          parseInt(pageNumber) + 1,
          pageSize,
          agentId,
          authToken
        ).then((result) => {
          console.log(result.data);
          if (result && result.data && result.data.userData) {
            setUserData(result.data.userData);
            setResponseData(result.data);
          }
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.header}>
        <Typography color="textPrimary">Patients</Typography>
      </Breadcrumbs>

      <Grid container spacing={0} className={classes.gridContainer}>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Box display="flex" flexDirection="column" alignItems="flex-start" height="100%">
            <Typography variant="h1" className={classes.title}>
              Patients List
            </Typography>
            <Box className={classes.dataGrid}>
              <DataGrid
                disableSelectionOnClick={true}

                rows={userData}
                columns={patientColumns}
                pageSize={10}
                rowCount={
                  responseData && responseData.totalCount
                    ? responseData.totalCount
                    : ''
                }
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Button
        className={classes.logoutButton}
        variant="contained"
        style={{ backgroundColor: "rgb(5, 38, 121)", color: "white" }}
        onClick={handleLogout}
      >
        Logout
      </Button>

    </Box>
  );
}
